import { Component, EventEmitter, inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { ExternalAttainedStudy, Urn } from 'common-typescript/types';
import { map, tap } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { getLabelState } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { ExternalAttainedStudyService } from 'sis-components/service/external-attained-study.service';

import { EmrexAttainmentDialogComponent } from '../../emrex-attainment-dialog/emrex-attainment-dialog.component';
import {
    SelectEmrexAttainmentDialogComponent,
} from '../../select-emrex-attainment-dialog/select-emrex-attainment-dialog.component';

@Component({
    selector: 'app-prior-studies-edit',
    templateUrl: './prior-studies-edit.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PriorStudiesEditComponent {

    @Input() formGroup: FormGroup;
    @Input() title: string;
    @Input() externalAttainedStudies: ExternalAttainedStudy[] = [];
    @Input() emrexIntegrationEnabled: boolean = false;
    @Output() delete = new EventEmitter<void>();
    @Output() selectExternalAttainedStudy = new EventEmitter<ExternalAttainedStudy>();

    private dialogService = inject(FudisDialogService);
    private externalAttainedStudyService = inject(ExternalAttainedStudyService);
    private errorHandler = inject(AppErrorHandler);
    private fb = inject(SisFormBuilder);

    getLabelState = getLabelState;
    selectedExternalAttainedStudy: ExternalAttainedStudy;
    readonly languageCodeBookUrn = 'urn:code:language';

    emrexOrganisationFound = false;

    onAttainmentLanguageChange(language: Urn): void {
        if (language !== this.attainmentLanguage.value) {
            this.attainmentLanguage.setValue(language);
            this.attainmentLanguage.markAsTouched();
            this.attainmentLanguage.markAsDirty();
        }
    }

    openSelectEmrexAttainmentDialog() {
        this.dialogService.open(SelectEmrexAttainmentDialogComponent, {
            data: {
                externalAttainedStudies: this.externalAttainedStudies,
                selection: this.selectedExternalAttainedStudy,
            },
        })
            .afterClosed()
            .pipe(
                tap((result) => {
                    if (!!result) {
                        this.selectExternalAttainedStudy.next(result.selection);
                    }
                }),
            )
            .subscribe((result) => {
                if (!!result) {
                    this.selectedExternalAttainedStudy = result.selection;
                    if (this.selectedExternalAttainedStudy) {
                        this.populateFieldsFromEmrex();
                    } else {
                        this.clearFields();
                    }
                }
            });
    }

    openEmrexAttainmentDialog() {
        if (this.selectedExternalAttainedStudy) {
            this.dialogService.open(EmrexAttainmentDialogComponent, {
                data: {
                    externalAttainedStudy: this.selectedExternalAttainedStudy,
                },
            });
        }
    }

    get attainmentDate(): FormControl {
        return this.formGroup?.get('attainmentDate') as FormControl;
    }

    get externalAttainedStudyId(): FormControl {
        return this.formGroup?.get('externalAttainedStudyId') as FormControl;
    }

    get attainmentLanguage(): FormControl {
        return this.formGroup?.get('attainmentLanguage') as FormControl;
    }

    get code(): FormControl {
        return this.formGroup?.get('code') as FormControl;
    }

    get credits(): FormControl {
        return this.formGroup?.get('credits') as FormControl;
    }

    get description(): FormControl {
        return this.formGroup?.get('description') as FormControl;
    }

    get grade(): FormControl {
        return this.formGroup?.get('grade') as FormControl;
    }

    get gradeScale(): FormControl {
        return this.formGroup?.get('gradeScale') as FormControl;
    }

    get name(): FormControl {
        return this.formGroup?.get('name') as FormControl;
    }

    get organisation(): FormControl {
        return this.formGroup?.get('organisation') as FormControl;
    }

    private populateFieldsFromEmrex() {
        const selectedStudy = this.selectedExternalAttainedStudy;
        this.externalAttainedStudyId.setValue(selectedStudy.id);
        this.externalAttainedStudyService.getExternalAttainedStudyAsPriorStudies(selectedStudy)
            .pipe(
                map((priorStudy) => {
                    this.name.setValue(priorStudy.name);
                    this.code.setValue(priorStudy.code);

                    this.emrexOrganisationFound = !!priorStudy.organisation;
                    this.organisation.setValue(priorStudy.organisation);
                    this.attainmentDate.setValue(this.fb.formatLocalDate(priorStudy.attainmentDate));

                    this.credits.setValue(priorStudy.credits);
                    this.attainmentLanguage.setValue(priorStudy.attainmentLanguage);

                    this.grade.setValue(priorStudy.grade);
                    this.gradeScale.setValue(priorStudy.gradeScale);

                    this.description.setValue(priorStudy.description);
                }),
                this.errorHandler.defaultErrorHandler(),
            ).subscribe();
    }

    private clearFields() {
        this.externalAttainedStudyId.reset();
        this.name.reset();
        this.code.reset();
        this.emrexOrganisationFound = false;
        this.organisation.reset();
        this.attainmentDate.reset();
        this.credits.reset();
        this.attainmentLanguage.reset();
        this.grade.reset();
        this.gradeScale.reset();
        this.description.reset();
    }
}
