<div class="top-navigation" *transloco="let t">
  <div class="top-navigation__left" [class.top-navigation__left--overflow]="navCollapsed | async" [class.top-navigation__left--calc]="navCollapseCalc | async">
    <div class="d-flex align-items-center mh-100">
      <ng-container *ngIf="!focusMode()">
        <a *ngIf="homeState"
           [uiSref]="homeState"
           class="top-navigation__link top-navigation__link--frontpage"
           [attr.aria-current]="activeIndex === -1 ? 'page' : false"
           [attr.aria-label]="t('NAVIGATION.FRONTPAGE')"
           [attr.data-cy]="'top-navigation-frontpage-link'">
          <ng-container *ngTemplateOutlet="sisuLogo"/>
        </a>
        <a *ngIf="!homeState && homeUrl"
           [href]="homeUrl"
           class="top-navigation__link top-navigation__link--frontpage"
           [attr.aria-current]="activeIndex === -1 ? 'page' : false"
           [attr.aria-label]="t('NAVIGATION.FRONTPAGE')"
           [attr.data-cy]="'top-navigation-frontpage-link'">
          <ng-container *ngTemplateOutlet="sisuLogo"/>
        </a>
        <ng-template #sisuLogo>
          <img ngSrc="Sisu_logo_nega.svg" alt="Sisu logo" [width]="44" [height]="18"
               class="top-navigation__link--frontpage__logo"/>
        </ng-template>
        <div class="top-navigation__divider" aria-hidden="true"></div>
        <fudis-icon *ngIf="navCollapseCalc | async" class="top-navigation__link__calc-icon" [icon]="'menu'" [color]="'white'"/>
        <button
          *ngIf="navCollapsed | async"
          class="top-navigation__link top-navigation__link__menu-toggle"
          [class.top-navigation__link--hidden]="navCollapseCalc | async"
          [attr.aria-expanded]="mobileMenuOpen"
          [attr.aria-label]="t('ARIA_LABEL.MAIN_MENU')"
          (keyup)="onKeyboardButtonInteraction($event)"
          (click)="toggleMobileMenu()">
          <fudis-icon [icon]="mobileMenuOpen ? 'close-big' : 'menu'" [color]="'white'"/>
        </button>
      </ng-container>
      <ng-container *ngIf="focusMode()">
        <a #exitLinkFirst *ngIf="isValidState(focusMode().exitState, focusMode().exitStateParams)"
           [uiSref]="focusMode().exitState"
           [uiParams]="focusMode().exitStateParams"
           (click)="moveFocusToCartButton()"
           class="top-navigation__link"
           data-cy="top-navigation-focus-mode-exit-link">
          <ng-container *ngTemplateOutlet="exitLinkContent"/>
        </a>
        <a #exitLinkSecond *ngIf="focusMode().exitUrl"
           [href]="focusMode().exitUrl"
           (click)="moveFocusToCartButton()"
           class="top-navigation__link"
           data-cy="top-navigation-focus-mode-exit-link">
          <ng-container *ngTemplateOutlet="exitLinkContent"/>
        </a>
        <ng-template #exitLinkContent>
          <fudis-icon icon="arrow-solid" rotate="flip-180" color="white"/>
          <span class="d-none d-xl-inline">{{t('SIS_COMPONENTS.BUTTON.EXIT')}}</span>
          <span class="visually-hidden d-xl-none">{{t('SIS_COMPONENTS.BUTTON.EXIT')}}</span>
        </ng-template>
        <span class="top-navigation__link top-navigation__link--active" aria-current="page">
          {{t(focusMode().titleKey)}}
        </span>
      </ng-container>
    </div>
    <div #leftNavigation class="top-navigation__left__nav-wrapper">
      <div *ngIf="mobileMenuOpen && displayName" class="top-navigation__left__nav-wrapper__user" id="user-info">
        <p class="top-navigation__left__nav-wrapper__user-name">{{displayName}}</p>
        <span class="d-block px-2" aria-hidden="true">|</span>
        <p class="top-navigation__left__nav-wrapper__user-role" data-cy="user-name-role">{{activeRoleNameKey | translate}}</p>
      </div>
      <nav *ngIf="!focusMode()"
           class="top-navigation__left__nav-wrapper__nav-el"
           [class.top-navigation__left__nav-wrapper__nav-el--closed]="!mobileMenuOpen"
           [attr.aria-label]="t('ARIA_LABEL.MAIN_MENU')">
        <ul #navList class="top-navigation__list">
          <li *ngFor="let item of mainNavigationItems; index as index"
              class="top-navigation__list__item">
            <a *ngIf="isValidState(item.toState); else externalNavLink"
               class="top-navigation__link top-navigation__list__item__link"
               [uiSref]="isValidState(item.toState) ? item.toState : null"
               [uiParams]="isValidState(item.toState) ? item.toParams: null"
               [class.top-navigation__link--active]="index === activeIndex"
               [attr.aria-current]="index === activeIndex ? 'page' : false"
               [attr.data-cy]="item.linkId"
               [id]="item.linkId"
               (keydown)="onKeyboardButtonInteraction($event)"
               (keydown.escape)="blurMobileMenu($event)"
               (blur)="blurMobileMenu($event)">
              <ng-container *ngTemplateOutlet="navLinkContent"/>
            </a>
            <ng-template #externalNavLink>
              <a class="top-navigation__link top-navigation__list__item__link"
                 [href]="item.toUrl"
                 [class.top-navigation__link--active]="index === activeIndex"
                 [attr.aria-current]="index === activeIndex ? 'page' : false"
                 [attr.data-cy]="item.linkId"
                 [id]="item.linkId"
                 (keydown)="onKeyboardButtonInteraction($event)"
                 (keydown.escape)="blurMobileMenu($event)"
                 (blur)="blurMobileMenu($event)">
                <ng-container *ngTemplateOutlet="navLinkContent"/>
              </a>
            </ng-template>
            <ng-template #navLinkContent>
              {{t(item.translationKey)}}
              <fudis-icon *ngIf="index === activeIndex" [icon]="'check'" [color]="'primary'" class="d-block d-xl-none float-end"/>
            </ng-template>
          </li>
        </ul>
      </nav>
      <div #rightNavigationMobile *ngIf="mobileMenuOpen && !focusMode()" class="d-lg-none">
        <sis-top-navigation-icons [mobileMode]="true"
                                  [frontendName]="frontendName"
                                  (keydownPressed)="onKeyboardButtonInteraction($event)"
                                  (blurMobileMenuIcons)="blurMobileMenu($event)"/>
        <div role="menu">
          <sis-university-menu [mobileMode]="true" (keydownPressed)="onKeyboardButtonInteraction($event)" (blurMobileUniversityMenu)="blurMobileMenu($event)"/>
          <sis-user-role-menu [mobileMode]="true" (keydownPressed)="onKeyboardButtonInteraction($event)" (blurMobileUserRoleMenu)="blurMobileMenu($event)"/>
          <sis-help-and-feedback-menu [mobileMode]="true" (keydownPressed)="onKeyboardButtonInteraction($event)" (blurMobileHelpAndFeedbackMenu)="blurMobileMenu($event)"/>
          <sis-logout *ngIf="authenticated()" [mobileMode]="true" (keydownPressed)="onKeyboardButtonInteraction($event)" (blurLogout)="blurMobileMenu($event)"/>
          <sis-login *ngIf="!authenticated()" [mobileMode]="true" (keydownPressed)="onKeyboardButtonInteraction($event)" (blurMobileLogin)="blurMobileMenu($event)"/>
        </div>
      </div>
    </div>
  </div>
  <div #rightNavigation class="top-navigation__right">
    <ng-container *ngIf="!focusMode()">
      <sis-top-navigation-icon *ngIf="frontendName === 'STUDENT'" [variant]="'open-university-cart'" [initialFocus]="focusToShoppingCart" class="d-lg-none"/>
      <sis-top-navigation-icons [initialFocus]="focusToShoppingCart" [frontendName]="frontendName" class="d-none d-lg-block"/>
    </ng-container>

    @switch (authenticated()) {
      <!-- authenticated() is undefined until login state has been resolved; neither option should be rendered at that point -->
      @case (true) {
        <sis-user-settings-menu class="d-none d-lg-block"/>
      }
      @case (false) {
        <sis-login class="d-none d-lg-block"/>
      }
    }
  </div>
</div>
