import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { OpenUniversityCart, PaymentId } from 'common-typescript/types';
import { catchError, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';
import { PrivatePersonEntityService } from 'sis-components/service/private-person-entity.service';

import { OpenUniversityCartUtilsService } from '../../service/open-university-cart-utils.service';
import { OpenUniversityErrorHelperService } from '../../service/open-university-error-helper.service';
import { CART_ERROR_MSG_TYPE } from '../cart-error/cart-error.component';
import { CART_PURCHASE_WIZARD_STEPS, CART_PURCHASE_WIZARD_STEPS_AUTO_ACTIVATE, STEP } from '../constants';

@Component({
    selector: 'app-cart-confirmation',
    templateUrl: './cart-confirmation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CartConfirmationComponent implements OnInit {

    constructor(
        private openUniversityCartCustomerService: OpenUniversityCartCustomerService,
        private stateService: StateService,
        private appErrorHandler: AppErrorHandler,
        private authService: AuthService,
        private privatePersonService: PrivatePersonEntityService,
        private openUniversityErrorHelperService: OpenUniversityErrorHelperService,
        private openUniversityCartUtilsService: OpenUniversityCartUtilsService,
    ) {}

    @ViewChild('currentStepNameFocus', {}) currentStepNameFocus: ElementRef;

    @Input() paymentId: PaymentId;

    showAutoConfirmWizard: boolean;
    wizardStepNames: string[];
    readonly currentStep = STEP.CONFIRMATION;

    cart: OpenUniversityCart;
    contactInfo$: Observable<{ link: string, isEmail: boolean, contactLinkText: string } | null>;

    ngOnInit(): void {
        if (this.paymentId?.length > 0) {
            this.openUniversityCartCustomerService.getCartByPaymentId(this.paymentId)
                .pipe(
                    take(1),
                    catchError((err) => {
                        // This can happen when anonymous user stays in payment view too long and session will timeout.
                        if (err.status === 403 || err.status === 401) {
                            this.sessionTimeout();
                        }
                        throw err;
                    }),
                    switchMap(cart => this.openUniversityCartUtilsService.activateCartForStudentIfIsValidStudent(this.paymentId, cart)),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe({
                    next: (cart) => {
                        this.cart = cart;
                        setTimeout(() => this.currentStepNameFocus?.nativeElement?.focus?.());
                    },
                });
        }

        this.initWizardConfirmTypeForUser();
        this.contactInfo$ = this.openUniversityErrorHelperService.fetchOpenUniversityContactInfoEmailOrUrlOrNull();
    }

    redirectToStudiesActivateWizard(): void {
        this.stateService.go(
            'student.open-university-studies-activate',
            { openUniversityCartId: this.cart.id, activationCode: this.cart.activationCode },
        );
    }

    private sessionTimeout() {
        this.stateService.go('student.open-university-cart.error', { errorType: CART_ERROR_MSG_TYPE.SESSION_TIMEOUT_WHILE_IN_PAYMENT_SYSTEM });
    }

    goToFrontPage() {
        this.stateService.go('student.logged-in.frontpage', {},
                             { custom: { skipConfirmationDialog: true } });
    }

    private initWizardConfirmTypeForUser() {
        if (this.authService.loggedIn()) {
            this.privatePersonService.getIsValidStudent(this.authService.personId())
                .pipe(this.appErrorHandler.defaultErrorHandler())
                .subscribe(isValid => {
                    this.wizardStepNames = (isValid ? CART_PURCHASE_WIZARD_STEPS_AUTO_ACTIVATE : CART_PURCHASE_WIZARD_STEPS)
                        .map(step => step.wizardProgressStep);
                    this.showAutoConfirmWizard = isValid;
                });
        } else {
            this.wizardStepNames = CART_PURCHASE_WIZARD_STEPS.map(step => step.wizardProgressStep);
            this.showAutoConfirmWizard = false;
        }
    }
}
