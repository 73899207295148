import { Injectable } from '@angular/core';
import { OpenUniversityCart, PaymentId } from 'common-typescript/types';
import { catchError, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';
import { PrivatePersonEntityService } from 'sis-components/service/private-person-entity.service';

@Injectable({ providedIn: 'root' })
export class OpenUniversityCartUtilsService {

    constructor(
        private privatePersonEntityService: PrivatePersonEntityService,
        private authService: AuthService,
        private openUniversityCartCustomerService: OpenUniversityCartCustomerService,
    ) {}

    activateCartForStudentIfIsValidStudent(paymentId: PaymentId, cart: OpenUniversityCart): Observable<OpenUniversityCart> {
        if (!this.authService.loggedIn()) {
            return of(cart);
        }

        return this.privatePersonEntityService.getIsValidStudent(this.authService.personId())
            .pipe(
                switchMap((isValidStudent) => isValidStudent && cart.state !== 'ACTIVATED' ? this.openUniversityCartCustomerService.activateOpenUniversityCartWithActivationCode(cart.id, cart.activationCode) : of(cart)),
                catchError(_ => of(cart)),
            );
    }
}
