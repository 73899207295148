import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { SisCommonModule } from 'sis-common/sis-common.module';
import {
    alertsServiceProvider,
} from 'sis-components/ajs-upgraded-modules';
import { AttainmentApplicationStructureModule } from 'sis-components/applications/attainment-application-structure/attainment-application-structure.module';
import { ModuleAttainmentApplicationModule } from 'sis-components/applications/module-attainment-application/module-attainment-application.module';
import {
    TextWithDefaultMissingValueComponent,
} from 'sis-components/applications/text-with-default-missing-value/text-with-default-missing-value.component';
import { SisComponentsHybridModule } from 'sis-components/sis-components.module.hybrid';
import { StudyRightModule } from 'sis-components/studyRight/study-right.module';

import { degreeProgrammeAttainmentApplicationServiceProvider } from '../../ajs-upgraded-modules';
import { StudentCommonModule } from '../../common/common.module';

import { ApplicationModalService } from './application-modal.service';
import { studentApplicationStates } from './applications.route-ng';
import { CancelWorkflowDialogComponent } from './cancel-workflow-dialog/cancel-workflow-dialog.component';
import { AddPriorLearningModalComponent } from './create/add-prior-learning-modal/add-prior-learning-modal.component';
import { ApplicationAttachmentsComponent } from './create/application-attachments/application-attachments.component';
import { AttachmentsPreviewComponent } from './create/attachments-preview/attachments-preview.component';
import {
    CreateDegreeProgrammeAttainmentApplicationComponent,
} from './create/create-degree-programme-attainment-application/create-degree-programme-attainment-application.component';
import { EditContactInfoForGraduationComponent } from './create/create-degree-programme-attainment-application/edit-contact-info-for-graduation/edit-contact-info-for-graduation.component';
import {
    CustomAttainmentApplicationSupplementWizardComponent,
} from './create/custom-attainment-application-supplement-wizard/custom-attainment-application-supplement-wizard.component';
import { CustomAttainmentBasicInfoEditComponent } from './create/custom-attainment-basic-info-edit/custom-attainment-basic-info-edit.component';
import { CaaAttainmentInfoComponent } from './create/custom-attainment-workflow-application-wizard/caa-attainment-info/caa-attainment-info.component';
import { CaaConfirmationComponent } from './create/custom-attainment-workflow-application-wizard/caa-confirmation/caa-confirmation.component';
import { CustomAttainmentWorkflowApplicationWizardComponent } from './create/custom-attainment-workflow-application-wizard/custom-attainment-workflow-application-wizard.component';
import { EmrexAttainmentDialogComponent } from './create/emrex-attainment-dialog/emrex-attainment-dialog.component';
import { CreateExtensionApplicationComponent } from './create/extension-application/create-extension-application.component';
import { PlanModuleSelectorComponent } from './create/plan-module-selector/plan-module-selector.component';
import { PriorCompetenceEditComponent } from './create/prior-learning-and-attachments-edit/prior-competence-edit/prior-competence-edit.component';
import { PriorLearningAndAttachmentsEditComponent } from './create/prior-learning-and-attachments-edit/prior-learning-and-attachments-edit.component';
import { PriorStudiesEditComponent } from './create/prior-learning-and-attachments-edit/prior-studies-edit/prior-studies-edit.component';
import { PriorLearningApplicationConfirmationComponent } from './create/prior-learning-application-confirmation/prior-learning-application-confirmation.component';
import { PriorLearningInclusionWorkflowApplicationWizardComponent } from './create/prior-learning-inclusion-workflow-application-wizard/prior-learning-inclusion-workflow-application-wizard.component';
import { PriorLearningInclusionWorkflowSupplementWizardComponent } from './create/prior-learning-inclusion-workflow-supplement-wizard/prior-learning-inclusion-workflow-supplement-wizard.component';
import { PriorLearningSubstitutionWorkflowApplicationWizardComponent } from './create/prior-learning-substitution-workflow-application-wizard/prior-learning-substitution-workflow-application-wizard.component';
import { PriorLearningSubstitutionWorkflowSupplementWizardComponent } from './create/prior-learning-substitution-workflow-supplement-wizard/prior-learning-substitution-workflow-supplement-wizard.component';
import {
    SelectEmrexAttainmentDialogComponent,
} from './create/select-emrex-attainment-dialog/select-emrex-attainment-dialog.component';
import { CustomAttainmentWorkflowSummaryComponent } from './custom-attainment-workflow/custom-attainment-workflow-summary/custom-attainment-workflow-summary.component';
import { CustomAttainmentWorkflowComponent } from './custom-attainment-workflow/custom-attainment-workflow.component';
import { DegreeProgrammeAttainmentWorkflowComponent } from './degree-programme-attainment-workflow/degree-programme-attainment-workflow.component';
import { ModuleAttainmentApplicationComponent } from './module-attainment-application/module-attainment-application.component';
import { ModuleContentWorkflowSummaryComponent } from './module-content-workflow/module-content-workflow-summary/module-content-workflow-summary.component';
import { ModuleContentWorkflowComponent } from './module-content-workflow/module-content-workflow.component';
import { WorkflowCancellationInfoModalComponent } from './module-content-workflow/workflow-cancellation-info-modal/workflow-cancellation-info-modal.component';
import { PriorLearningWorkflowContentComponent } from './prior-learning-workflow-content/prior-learning-workflow-content.component';
import { PriorLearningWorkflowComponent } from './prior-learning-workflow/prior-learning-workflow.component';
import { StudentApplicationsComponent } from './student-applications/student-applications.component';
import { StudyRightExtensionWorkflowDecisionSummaryComponent } from './study-right-extension-workflow/study-right-extension-workflow-decision-summary/study-right-extension-workflow-decision-summary.component';
import { StudyRightExtensionWorkflowSummaryComponent } from './study-right-extension-workflow/study-right-extension-workflow-summary/study-right-extension-workflow-summary.component';
import { StudyRightExtensionWorkflowComponent } from './study-right-extension-workflow/study-right-extension-workflow.component';
import { WorkflowStateBadgeComponent } from './workflow-state-badge/workflow-state-badge.component';

@NgModule({
    declarations: [
        AddPriorLearningModalComponent,
        AttachmentsPreviewComponent,
        CaaAttainmentInfoComponent,
        CaaConfirmationComponent,
        CreateDegreeProgrammeAttainmentApplicationComponent,
        CustomAttainmentWorkflowApplicationWizardComponent,
        CustomAttainmentBasicInfoEditComponent,
        CustomAttainmentApplicationSupplementWizardComponent,
        EditContactInfoForGraduationComponent,
        ModuleAttainmentApplicationComponent,
        PlanModuleSelectorComponent,
        PriorCompetenceEditComponent,
        PriorLearningAndAttachmentsEditComponent,
        PriorLearningApplicationConfirmationComponent,
        PriorLearningInclusionWorkflowApplicationWizardComponent,
        PriorLearningSubstitutionWorkflowApplicationWizardComponent,
        PriorStudiesEditComponent,
        EditContactInfoForGraduationComponent,
        PriorLearningWorkflowContentComponent,
        ModuleContentWorkflowSummaryComponent,
        ModuleContentWorkflowComponent,
        WorkflowCancellationInfoModalComponent,
        WorkflowStateBadgeComponent,
        PriorLearningSubstitutionWorkflowSupplementWizardComponent,
        PriorLearningInclusionWorkflowSupplementWizardComponent,
        ApplicationAttachmentsComponent,
        StudentApplicationsComponent,
        CreateExtensionApplicationComponent,
        TextWithDefaultMissingValueComponent,
        DegreeProgrammeAttainmentWorkflowComponent,
        CancelWorkflowDialogComponent,
        CustomAttainmentWorkflowComponent,
        PriorLearningWorkflowComponent,
        SelectEmrexAttainmentDialogComponent,
        EmrexAttainmentDialogComponent,
        CustomAttainmentWorkflowSummaryComponent,
        StudyRightExtensionWorkflowComponent,
        StudyRightExtensionWorkflowSummaryComponent,
        StudyRightExtensionWorkflowDecisionSummaryComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SisCommonModule,
        SisComponentsHybridModule,
        TranslateModule.forChild(),
        UIRouterModule.forChild({ states: studentApplicationStates }),
        ModuleAttainmentApplicationModule,
        AttainmentApplicationStructureModule,
        NgxFudisModule,
        StudyRightModule,
    ],
    providers: [
        StudentCommonModule,
        ApplicationModalService,
        alertsServiceProvider,
        degreeProgrammeAttainmentApplicationServiceProvider,
    ],
})
export class ApplicationsModule {
}
